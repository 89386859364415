// Core
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Constants
import modalTypes from 'constants/modalTypes';

// Utils
import cloneDeep from 'lodash/cloneDeep';
import { transliterate as tr } from 'transliteration';

// Data
import { radioOutlets } from './radioOutlets';

// Context
import { UIContext } from 'contexts/UIContext';
import { LangContext } from 'contexts/LangContext';

// UI
import { BottomNavi, Loading } from 'components';
import { RadioGroup } from 'components/Radio';
import { RootContext } from 'contexts/RootContext';
import { accounts } from 'services';
import CheckBoxOutletsV2 from '../../../../../../components/CheckBoxOutletsV2/CheckBoxOutletsV2';
import { FormInput } from 'components/Form/FormInput';
import OutletsBlock from './OutletsBlock/OutletsBlock';

// Styles
import styles from './OutletsTakeDown.module.css';
import s from '../RepertoireReleaseTakenDown.module.css';

const OutletsTakeDown = ({ releases, releasesRadio, checkedReleases }) => {
	const accountId = localStorage.getItem('accountId');
	const { id: releaseId } = useParams();
	const { lang } = useContext(LangContext);
	const location = useLocation();
	const typeId = location?.state?.typeId;
	const takenDownsArr = location?.state?.takenDownsArr;
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [outletsRadio, setOutletsRadio] = useState('inc');
	const [errors, setErrors] = useState({});
	const [takenDown, setTakenDown] = useState([]);
	const [allOutlets, setAllOutlets] = useState([]);
	const [checks, setChecks] = useState([]);
	const [allChecked, setAllChecked] = useState(false);
	const [checked, setChecked] = useState([]);
	const [notEarlyUsedOutlets, setNotEarlyUsedOutlets] = useState([]);
	const [reasonsData, setReasonsData] = useState('1');
	const [reasonInput, setReasonInput] = useState({});
	const [isSubmitnBtnEnable, setIsSubmitBtnEnable] = useState(
		outletsRadio === 'inc' ? false : true
	);
	const [relatedOutlets, setRelatedOutlets] = useState([]);
	const [releaseInfo, setReleaseInfo] = useState();

	const { showModal } = useContext(UIContext);
	const {
		postReleaseUpdatedTakedowns,
		getReleaseTakedowns,
		getTakedownsOutlets,
	} = useContext(RootContext);

	const radioReasons = [
		{
			text: (
				<FormattedMessage id={'rod.release.label.takendown_rightholder_wish'} />
			),
			value: '1',
			disabled: false,
		},
		{
			text: (
				<FormattedMessage id={'rod.release.label.takendown_rights_violation'} />
			),
			value: '2',
			disabled: false,
		},
		{
			text: (
				<FormattedMessage
					id={'rod.release.label.takendown_agreement_termination'}
				/>
			),
			value: '3',
			disabled: false,
		},
		{
			value: '4',
			disabled: false,
			children: (
				<FormattedMessage id={'rod.release.label.takendown_indicate_reason'}>
					{(placeholder) => (
						<FormInput
							type="muiInput"
							name={'reason'}
							onChange={handleReasonInput}
							errors={errors}
							data={reasonInput}
							disabled={reasonsData.toString() !== '4'}
							className={s.ReasonInput}
							label={placeholder}
						/>
					)}
				</FormattedMessage>
			),
		},
	];

	const handleReasonInput = (field) => (e) => {
		setErrors({});
		setReasonInput({ reason: e.target.value });
	};

	const handleReasons = (e) => {
		setReasonsData(e.currentTarget.value);
	};

	const getCheckedOutlets = (item) => {
		switch (item.checked) {
			case true:
				const youtubeOutlet = checks.find((outlet) => outlet.id === 21859);
				if (item.id === 436356 && !youtubeOutlet.checked) {
					youtubeOutlet['checked'] = true;
					setChecked((prev) => [...prev, item, youtubeOutlet]);
				} else setChecked((prev) => [...prev, item]);

				break;
			case false:
				const youtubeIdOutlet = checks.find((outlet) => outlet.id === 436356);
				if (item.id === 21859 && youtubeIdOutlet?.checked) {
					youtubeIdOutlet['checked'] = false;
					const data = checked.filter(
						(i) => i.id !== item.id && i.id !== 436356
					);
					setChecked(data);
				} else {
					const data = checked.filter((i) => i.id !== item.id);
					setChecked(data);
				}
				break;
			default:
				break;
		}
	};

	const outletsRadioHandler = (e) => {
		setOutletsRadio(e.currentTarget.value);

		if (releasesRadio === 'current') {
			if (e.currentTarget.value === 'all') {
				setAllChecked(true);
				setIsSubmitBtnEnable(true);
			} else {
				setAllChecked(false);
				if (!checked.length) setIsSubmitBtnEnable(false);
			}
		} else {
			if (e.currentTarget.value === 'all') {
				setAllChecked(true);
				setRelatedOutlets((prevOutlets) =>
					prevOutlets.map((item) => [
						item[0],
						item[1].map((outlet) =>
							outlet.status !== 'takendown'
								? { ...outlet, checked: true }
								: outlet
						),
					])
				);
			} else {
				setAllChecked(false);
				if (!checked.length) setIsSubmitBtnEnable(false);
			}
		}
	};

	const submitTakenDown = () => {
		if (parseInt(reasonsData) === 4 && !reasonInput.reason) {
			return setErrors({ reason_input: [{ rule: 'required' }] });
		}

		if (releasesRadio === 'current') {
			if (allChecked) {
				let outletPayload = [];
				if (notEarlyUsedOutlets.length > 0) {
					outletPayload = notEarlyUsedOutlets.map((item) => item.id);
				} else if (checks.length > 0) {
					outletPayload = checks
						.filter((item) => item.status !== 'takedown')
						.map((item) => item.id);
				}
				postReleaseUpdatedTakedowns(accountId, releaseId, {
					outlets: outletPayload,
					reason: {
						id: reasonsData.toString(),
						message: reasonInput?.reason || '',
					},
				})
					.then((res) => {
						history.push(
							`/repertoire/releases/${releaseId}/takendown/confirmation`
						);
					})
					.catch((err) => {
						console.error('Error:', err);
					});
			} else {
				const data = checks
					.filter((item) => item.checked)
					.map((item) => item.id);
				if (!data.length) return;

				postReleaseUpdatedTakedowns(accountId, releaseId, {
					outlets: data,
					reason: {
						id: reasonsData.toString(),
						message: reasonInput?.reason || '',
					},
				})
					.then((res) => {
						history.push(
							`/repertoire/releases/${releaseId}/takendown/confirmation`
						);
					})
					.catch((err) => {
						console.error('Error:', err);
					});
			}
		} else {
			const requests = relatedOutlets.map(([releaseId, outlets]) => {
				const checkedOutlets = outlets
					.filter((outlet) => outlet.checked && outlet.status !== 'takedown')
					.map((item) => item.id);
				if (checkedOutlets.length === 0) {
					return Promise.resolve(null); // Skip empty submissions
				}
				return postReleaseUpdatedTakedowns(accountId, parseInt(releaseId), {
					outlets: checkedOutlets,
					reason: {
						id: reasonsData.toString(),
						message: reasonInput?.reason || '',
					},
				});
			});
			Promise.allSettled(requests).then((results) => {
				const succeeded = results.some(
					(result) => result.status === 'fulfilled'
				);
				if (succeeded) {
					// Redirect to confirmation screen if at least one request succeeded
					history.push(
						`/repertoire/releases/${releaseId}/takendown/confirmation`
					);
				} else {
					console.error('All requests failed');
				}
			});
		}
	};

	const takedownHandler = () => {
		showModal(
			{
				title: <FormattedMessage id={'rod.release.release_fallback'} />,
				text: (
					<FormattedMessage id={'rod.release.takendown.confirmation-text'} />
				),
				onAction: () => {
					submitTakenDown();
				},
				confirmBtnTxt: <FormattedMessage id={'rod.performance_work.yes'} />,
				declineBtnTxt: <FormattedMessage id={'rod.modal.cancel'} />,
			},
			modalTypes.FULL_MODAL
		)();
	};

	const areAllOutletsUnchecked = (relatedOutlets) => {
		return !relatedOutlets.some(([releaseId, outlets]) =>
			outlets.some((outlet) => outlet.checked)
		);
	};

	const handleCheckedRelatedOutlet = (releaseId, id) => {
		setRelatedOutlets((prevOutlets) =>
			prevOutlets.map((item) => [
				item[0],
				item[1].map((outlet) =>
					item[0] === releaseId && outlet.id === id
						? { ...outlet, checked: !outlet.checked }
						: outlet
				),
			])
		);
	};

	const getAllOutlets = (language, id) => {
		accounts
			.getOutletsWithTypes(language, id)
			.then((outlets) => {
				outlets = outlets.data.data;
				const e = {};
				e.currentTarget = { value: 'inc' };
				outletsRadioHandler(e);
				setAllOutlets(outlets);
			})
			.catch((error) => {
				console.error('Error', error);
			});
	};

	useEffect(() => {
		if (releasesRadio === 'current') {
			if (typeId) {
				getAllOutlets(lang, typeId);
			} else {
				accounts
					.getReleaseInfoForBrowsing(accountId, releaseId)
					.then((res) => {
						setReleaseInfo(res.data.data);
						getAllOutlets(lang, res.release_type_id);
					})
					.catch((error) => {
						console.error('Error', error);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		setIsLoading(true);
		accounts
			.getOutlets(lang)
			.then((res) => {
				res = res.data.data;
				setAllOutlets(res);
			})
			.catch((error) => {
				console.error('Error', error);
			})
			.finally(() => setIsLoading(false));
	}, [lang]);

	useEffect(() => {
		if (!checkedReleases.includes(+releaseId)) {
			checkedReleases.unshift(+releaseId);
		}

		if (accountId) {
			getTakedownsOutlets(accountId, checkedReleases)
				.then((res) => {
					const modifiedOutlets = Object.entries(res).map(([key, outlets]) => [
						key,
						outlets.map((outlet) => ({
							...outlet,
							checked: false,
						})),
					]);
					// Sort modifiedOutlets so that the releaseId object is first
					modifiedOutlets.sort(([keyA], [keyB]) => {
						if (keyA === releaseId) return -1;
						if (keyB === releaseId) return 1;
						return 0;
					});
					setRelatedOutlets(modifiedOutlets);
				})
				.catch((error) => {
					console.error('Error', error);
				})
				.finally(() => setIsLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		if (accountId) {
			accounts
				.getReleaseInfoForBrowsing(accountId, releaseId)
				.then((res) => {
					setReleaseInfo(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (takenDownsArr && takenDownsArr.length) {
			setChecks(takenDownsArr);
			setTakenDown(takenDownsArr);
		} else {
			getReleaseTakedowns(accountId, releaseId)
				.then((res) => {
					setChecks(res);
					setTakenDown(res);
				})
				.catch((err) => {
					console.error('Error:', err);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const notUsed = [];
		const takenDownTitles = [];
		if (allOutlets.length) {
			takenDown.map((item) => {
				if (item.is_aggregator) {
					takenDownTitles.push(item.id);
					takenDownTitles.push(item.aggregator.id);
				} else {
					takenDownTitles.push(item.id);
				}
			});
			const data = allOutlets.filter((item) => {
				if (takenDownTitles.includes(item.id)) {
					const outlet = takenDown.find((outlet) => outlet.id === item.id);
					if (outlet && outlet.status !== 'shipping') {
						item.isLocked = true;
					} else {
						item.isLocked = false;
						notUsed.push(item);
						setNotEarlyUsedOutlets(notUsed);
					}
					return item;
				}
			});

			const dataIds = data.map((item) => item.id);
			const otherOutlets = takenDown.filter(
				(item) => !dataIds.includes(item.id)
			);

			let otherOutletsIncludeTranslate = [];
			if (otherOutlets.length) {
				const otherOutletsRu = cloneDeep(otherOutlets);
				const otherOutletsEn = cloneDeep(otherOutlets).map((item) => {
					item.title = tr(item.title);
					return item;
				});

				if (lang === 'en') {
					otherOutletsIncludeTranslate = otherOutletsEn.map((item) => {
						if (item.status !== 'shipping') {
							item.isLocked = true;
						} else {
							item.isLocked = false;
							notUsed.push(item);
							setNotEarlyUsedOutlets(notUsed);
						}
						return item;
					});
				} else {
					otherOutletsIncludeTranslate = otherOutletsRu.map((item) => {
						if (item.status !== 'shipping') {
							item.isLocked = true;
						} else {
							item.isLocked = false;
							notUsed.push(item);
							setNotEarlyUsedOutlets(notUsed);
						}
						return item;
					});
				}
			}

			const result = [...data, ...otherOutletsIncludeTranslate];
			setChecks(result);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [takenDown, allOutlets, allChecked]);

	useEffect(() => {
		if (checks.length) {
			const data = checks.filter((item) => item.checked);

			if (data.length) {
				setIsSubmitBtnEnable(true);
			} else {
				setIsSubmitBtnEnable(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	return (
		<>
			<div className={styles.container}>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<div className={styles.title}>
							<FormattedMessage id={'rod.release.release_fallback_enable'} />
						</div>
						<RadioGroup
							className={styles.radioGroup}
							name={'outlets'}
							value={outletsRadio}
							onChange={outletsRadioHandler}
							items={radioOutlets}
						/>
						<div
							className={styles.outletsBlock}
							style={outletsRadio === 'all' ? { opacity: '0.5' } : {}}
						>
							{releasesRadio === 'current' ? (
								<div className={styles.table}>
									{checks.length > 0 ? (
										checks.map((item, index) => (
											<CheckBoxOutletsV2
												name={'check_box_outlets'}
												key={index}
												checks={checks}
												onChange={setChecks}
												item={item}
												allChecked={allChecked}
												index={index}
												getCheckedOutlets={getCheckedOutlets}
												isLocked={item.isLocked ? true : false}
												className={styles.outletCheckbox}
											/>
										))
									) : (
										<div className={styles.noData}>
											<FormattedMessage
												id={'rod.release.release.takendown.outlets.noData'}
											/>
										</div>
									)}
								</div>
							) : (
								<OutletsBlock
									relatedOutlets={relatedOutlets}
									handleCheckedRelatedOutlet={handleCheckedRelatedOutlet}
									outletsRadio={outletsRadio}
									releases={releases}
									releaseInfo={releaseInfo}
									allOutlets={allOutlets}
								/>
							)}
						</div>
						<div className={styles.reasonWrapper}>
							<div className={styles.title}>
								<FormattedMessage id={'rod.release.label.takendown_reason'} />
							</div>
							<RadioGroup
								className={styles.reasonsRadioGroup}
								name={'reasons'}
								value={reasonsData}
								onChange={handleReasons}
								items={radioReasons}
							/>
							{Object.keys(errors).includes('reason_input') && (
								<span className={styles.helper}>
									{
										<FormattedMessage
											id={`rod.error.${errors.reason_input[0].rule}`}
										/>
									}
								</span>
							)}
						</div>
					</>
				)}
			</div>

			<BottomNavi
				showPrevBtn
				back={() =>
					history.push(`/repertoire/releases/${releaseId}/takendown/releases`)
				}
				next={takedownHandler}
				nextText={'rod.release.release_fallback_enable'}
				disabled={
					(releasesRadio === 'current' && !isSubmitnBtnEnable) ||
					(releasesRadio === 'chosen' &&
						(!relatedOutlets.length || areAllOutletsUnchecked(relatedOutlets)))
				}
			/>
		</>
	);
};

export default OutletsTakeDown;
